<template>
  <div class="clients-container">
    <admin-header-vue
    v-if="showIndustry"
      alias="Industry"
      @onCreate="onCreate"
      pageName="" />
    <div class="user-table">
      <table-vue
        :labels="labels"
        :data="industryList"
        :dateFormat="dateFormat"
        :sortObj="sort"
        :totalCount="totalCount"
        :currentPage="currentPage"
        :limit="limit"
        :sortValidation="sortValidation"
        :titleData="'Industries'"
        :showEditAction="showEdit"
        :showDeleteAction="showDelete"
        :showAction="showEdit || showDelete"
        @searchText="searchText"
        @onUpdate="onUpdate"
        @onClick="onClick"
        @onChangeSort="onChangeSort"
        @updatePage="updatePage"
        @updateLimit="updateLimit" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import tableVue from '../../components/table.vue'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import { metaTitle } from '../../../../constant/data'

/** This component contains the code that allows you to view the industries present in the database and their details. From here the user can view/edit existing industries and navigate to the *Add industry* page. */
export default {
  name: 'Industry',
  metaInfo () {
    return {
      title: metaTitle.industries
    }
  },
  components: {
    tableVue,
    AdminHeaderVue
  },
  data () {
    return {
      labels: [{ name: 'Industry Id', alias: 'industry_id' }, { name: 'Industry Name', alias: 'industry_name' }, { name: 'Created At', alias: 'created_at' }, { name: 'Updated At', alias: 'updated_at' }],
      sortValidation: ['industry_id', 'industry_name', 'created_at', 'updated_at'],
      dateFormat: ['created_at', 'updated_at'],
      search: '',
      sort: { by: 'asc', field: 'industry_id' }
    }
  },
  computed: {
    ...mapState('industries', ['industryList', 'totalCount']),
    ...mapState('adminCommon', ['tableSelectionState']),
    ...mapState('user', ['userDetails']),
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    showIndustry () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.write)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.industries.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.industries.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.industries.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.industries.currentPage = newPage
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions('industries', ['getIndustryList']),
    /** This method is unused */
    // labelsFun () {
    //   console.log('Hello')
    //   return this.companyList.length ? Object.keys(this.companyList[0]) : []
    // },
    onClick (data) {
    },
    /** This method redirects the user to the *update industry* page/form and populates it with existing industry data from the database
     * @param data {Object} - The industry object that contains the details for the selected industry
     */
    onUpdate (data) {
      this.$router.push(`industry/update/${data.industry_id}`)
    },
    /** This method redirects the user to the *add industry* page
     * @public
     */
    onCreate () {
      this.$router.push('industry/add')
    },
    /** This method updates the sorting criteria of the table
     * @public
     */
    onChangeSort (type) {
      this.sort = type
      this.getData()
    },
    /** This method updates the table page that is being viewed
     * @public
     */
    updatePage (data) {
      this.currentPage = data
      this.getData()
    },
    /** This method updates the number of rows that are being shown by the table
     * @public
     */
    updateLimit (data) {
      this.limit = data
      this.getData()
    },
    /** This method updates the search keyword and triggers the *getData()* method
     * @param text {String} - The new search keyword
     */
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData()
      }
    },
    /** This method fetches the industry related data from the database, using the limit/search/sort parameters specified */
    getData () {
      this.getIndustryList({ limit: this.limit, skip: (this.currentPage - 1) * this.limit, orderBy: this.sort, search: this.search })
    }
  }
}
</script>

<style lang="scss" scoped>
.clients-container {
  margin: 20px 0px;
  border-radius: 4px;
  background: var(--primary);
}
</style>
